<template>
  <div v-if="loading">
    <clip-loader :loading="loading" color="primary"></clip-loader>
  </div>
  <div v-else>
    <div class="" v-if="item">
      <div>
        <div class="aab-vie-content">
          <div>
            <vs-prompt
              title="Exportation"
              class="export-options"
              @cancel="clearFields"
              @accept="exportToData"
              accept-text="Valider"
              cancel-text="Annuler"
              @close="clearFields"
              :active.sync="activePrompt"
            >
              <vs-input
                v-model="fileName"
                placeholder="Entrez le nom du fichier..."
                class="w-full"
              />
              <multiselect
                v-model="selectedFormat"
                :options="formats"
                class="my-4"
                placeholder="--Choisir--"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
                <template v-slot:noOptions>
                  <span>{{ global.selectNotFoundData }}</span>
                </template>
              </multiselect>

              <div class="flex">
                <span class="mr-4">Largeur automatique de cellule:</span>
                <vs-switch v-model="cellAutoWidth"
                  >Largeur automatique de cellule</vs-switch
                >
              </div>
            </vs-prompt>
            <div>
              <div class="flex">
                <!--<vs-button
                  class="w-full sm:w-auto mr-4"
                  type="border"
                  @click="activePrompt = true"
                  >
                  <span class="flex items-center">
                    <feather-icon
                      icon="PrinterIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>Exporter</span>
                  </span>
                </vs-button>-->
                <vs-button
                  class="w-full sm:w-auto mr-4"
                  type="border"
                  v-if="crrIDs.length !== 0"
                  @click="checkPayedMultipleQuittance"
                  >
                  <span class="flex items-center">
                    <feather-icon
                      icon="CreditCardIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>Payer la sélection</span>
                  </span>
                </vs-button>
                <vs-button
                  v-else
                  class="w-full sm:w-auto mr-4"
                  type="border"
                  disabled
                  >
                  <span class="flex items-center">
                    <feather-icon
                      icon="CreditCardIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>Payer la sélection</span>
                  </span>
                </vs-button>
              </div>
            </div>
            <div>
              <div class="w-auto" v-if="loading">
                <clip-loader :loading="loading" color="primary"></clip-loader>
              </div>
              <div v-else>
                <div v-if="dataProject.quittances && dataProject.quittances.length > 0">
                  <vs-table
                    multiple 
                    :data="dataProject.quittances"
                    v-model="quittanceSelected"
                    search
                    stripe
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th sort-key="quittance">N° quittance</vs-th>
                      <vs-th sort-key="startDateContributions"
                        >Date de début</vs-th
                      >
                      <vs-th sort-key="endDateContributions">Date de fin</vs-th>
                      <vs-th sort-key="accessoir">Accessoire</vs-th>
                      <vs-th sort-key="cotisation">Cotisation nette</vs-th>
                      <vs-th sort-key="cotisationAll"
                        >Cumul cotisations nettes soldées</vs-th
                      >
                      <vs-th sort-key="paymentStatus">Etat</vs-th>
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>
                        <vs-td :data="data[indextr].startDateContributions">
                          {{ formatDate(data[indextr].startDateContributions) }}
                        </vs-td>
                        <vs-td :data="data[indextr].endDateContributions">
                          {{ formatDate(data[indextr].endDateContributions) }}
                        </vs-td>
                        <vs-td :data="data[indextr].accessoir">
                          <div v-if="data[indextr].isDefault">
                            {{ onFormatAmount(propositionChoose.accessoir) }}
                          </div>
                          <div v-else>
                            {{ onFormatAmount(0) }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].cotisationTTC">
                          <div v-if="data[indextr].isDefault">
                            {{
                              onFormatAmount(
                                parseInt(
                                  data[indextr].cotisationTTC -
                                    propositionChoose.accessoir
                                )
                              )
                            }}
                          </div>
                          <div v-else>
                            {{ onFormatAmount(data[indextr].cotisationTTC) }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].cotisationTTC">
                          <div v-if="data[indextr].isDefault">
                            {{
                              onFormatAmount(
                                parseInt(
                                  data[indextr].cotisationTTC -
                                    propositionChoose.accessoir
                                )
                              )
                            }}
                          </div>
                          <div v-else>
                            {{ onFormatAmount(onCumulTotal(indextr + 1)) }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].paymentStatus">{{
                          allPaymentStatus.find(
                            (el) => el.id == data[indextr].paymentStatus
                          )
                            ? allPaymentStatus.find(
                                (el) => el.id == data[indextr].paymentStatus
                              ).label
                            : ""
                        }}</vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <div>
                              <vx-tooltip
                                color="primary"
                                text="Payer"
                                v-if="
                                  data[indextr].paymentStatus == 'required'
                                "
                              >
                                <div
                                  class="cursor-pointer"
                                  @click="checkPayed(data[indextr])"
                                >
                                  <feather-icon
                                    class="text-primary"
                                    icon="CreditCardIcon"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                              <vx-tooltip
                                v-else
                                color="primary"
                                :title="role.notAllowTitle"
                                :text="role.notAllowText"
                              >
                                <div
                                  class=""
                                >
                                  <feather-icon
                                    class="aa-vie-icon-silver"
                                    icon="CreditCardIcon"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                            </div>
                            <div>
                              <vx-tooltip
                                color="primary"
                                text="Télécharger"
                              >
                                <div
                                  class="ml-6 cursor-pointer"
                                  @click="CotisationDownloadFile(data[indextr], true)"
                                >
                                  <feather-icon
                                    class="text-primary"
                                    icon="DownloadIcon"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                            </div>
                            <div>
                              <vx-tooltip
                                v-if="data[indextr].paymentStatus === 'payOff'"
                                  position="left"
                                  color="primary"
                                  title="Détails paiement"
                                  :text="DetailsPayment(data[indextr], `${dataProject.subscription.subscriber.firstname} ${dataProject.subscription.subscriber.lastname}`)"
                                >
                                <div class="cursor-pointer ml-6">
                                  <feather-icon
                                    icon="InfoIcon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                            </div>
                            <div
                              class="ml-2"
                              v-show="woIsAction == data[indextr].id"
                            >
                              <clip-loader
                                :loading="
                                  loadingDownloadQuittance ||
                                  loadingUpdateQuittance
                                "
                                color="primary"
                                size="25px"
                              ></clip-loader>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "N°",
        "Date de début",
        "Date de fin",
        "Accessoire",
        "Cotisation nette",
        "Cumul cotisations nettes soldées",
        "Etat",
      ],
      headerVal: [
        "index",
        "startDateContributions",
        "endDateContributions",
        "accessoir",
        "cotisation",
        "cotisationAll",
        "paymentStatus",
      ],
      activePrompt: false,
      //end export section
      quittanceSelected: [],
      crrIDs: [],
      cumulAmountPayed: 0,
      loading: false,
      loadingDownloadQuittance: false,
      loadingUpdateQuittance: false,
      crrID: "",
      crrQuittanceData: {},
      crrQuittanceFile: "",
      woIsAction: "",
      propositionChoose: {},
      startDateContributions: "",
      endDateContributions: "",
      closeIt: true,
      popupAgregateur: false,
      popupMarkPayment: false,
      formUpdatedItem: {},
      formUpdated: {
        cotisationTTC: "",
      },
      selectedQuittance: {},
    };
  },
  components: {
    ClipLoader,
    Multiselect,
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
  },
  mounted() {
    this.initDataLocal();
    addKkiapayCloseListener(() => {
      this.closeIt = !this.closeIt
    })

    if(this.crrModuleName == 'AHR' || this.crrModuleName == 'AAE') {
      if (!window.callbackPaymentQuittanceRegistred) {
        window.callbackPaymentQuittanceRegistred = true;
        addSuccessListener((response) => {
          this.woIsAction = "";
          if (response.transactionId) {
            this.quittanceSelected = []
            this.crrIDs = []
            localStorage.removeItem("selectedQuittanceID")

            this.acceptAlert({
              color: "success",
              text: "Paiement en cours de validation",
              title: "Paiement",
            });
            this.closeIt = true
          } else {
            this.acceptAlert({
              color: "danger",
              text: "Erreur lors du paiement",
              title: "Paiement",
            });
          }
        })
      }
    }
  },
  props: {
    crrModuleName: String,
    item: String,
    dataProject: Object,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("projetContrat", ["allProjetContrats"]),
    ...mapGetters("general", [
      "rowsTable",
      "allPeriodicityOfPayment",
      "allPaymentStatus",
      "paymentUserTypes",
      "paymentMethod",
    ]),
    validateFormUpdatedQuittance() {
      return (
        !this.errors.any() &&
        this.formUpdated.cotisation != ""
      );
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("general", [
      "applyUploadFile",
      "applyGenerateCotisation",
      "applyGenerateCotisationSolde",
    ]),
    ...mapActions("projetContrat", ["applyPutQuittance",]),
    initDataLocal() {
      if (this.item) { // ID of project
        this.propositionChoose = this.dataProject.quote.aboutProposition.data.find(
          (el) => el.id == this.dataProject.quote.aboutProposition.selectedId
        );
        this.startDateContributions = mixin.methods.formatDate(
          this.dataProject.quittances[0].startDateContributions
        );

        this.endDateContributions = mixin.methods.formatDate(
          this.dataProject.quittances[this.dataProject.quittances.length - 1]
            .endDateContributions
        );
      }
    },
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.dataProject.quittances.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            startDateContributions: this.formatDate(el.startDateContributions),
            endDateContributions: this.formatDate(el.endDateContributions),
            accessoir: el.isDefault
              ? this.onFormatAmount(this.propositionChoose.accessoir)
              : this.onFormatAmount(0),
            cotisation: el.isDefault
              ? this.onFormatAmount(
                  parseInt(el.cotisationTTC - this.propositionChoose.accessoir)
                )
              : this.onFormatAmount(el.cotisationTTC),
            cotisationAll: el.isDefault
              ? this.onFormatAmount(
                  parseInt(el.cotisationTTC - this.propositionChoose.accessoir)
                )
              : this.onFormatAmount(this.onCumulTotal(index + 1)),
            paymentStatus: this.allPaymentStatus.find(
              (item) => item.id == el.paymentStatus
            ).label,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, ind) => {
          headerArray.push({ header: title, dataKey: this.headerVal[ind] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES QUITTANCES"
        );
      } else {
        let tableToPrint = [];
        this.dataProject.quittances.forEach((el, index) => {
          let jsonTmp = {
            startDateContributions: this.formatDate(el.startDateContributions),
            endDateContributions: this.formatDate(el.endDateContributions),
            accessoir: el.isDefault
              ? this.onFormatAmount(this.propositionChoose.accessoir)
              : this.onFormatAmount(0),
            cotisation: el.isDefault
              ? this.onFormatAmount(
                  parseInt(el.cotisationTTC - this.propositionChoose.accessoir)
                )
              : this.onFormatAmount(el.cotisationTTC),
            cotisationAll: el.isDefault
              ? this.onFormatAmount(
                  parseInt(el.cotisationTTC - this.propositionChoose.accessoir)
                )
              : this.onFormatAmount(this.onCumulTotal(index + 1)),
            paymentStatus: this.allPaymentStatus.find(
              (item) => item.id == el.paymentStatus
            ).label,
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "startDateContributions",
          "endDateContributions",
          "accessoir",
          "cotisation",
          "cotisationAll",
          "paymentStatus",
        ];

        let headerTitleTmp = [
          "Date de début",
          "Date de fin",
          "Accessoire",
          "Cotisation nette",
          "Cumul cotisations nettes soldées",
          "Etat",
        ];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    clearFields() {
      (this.filename = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    whoIs(type) {
      let types = this.paymentUserTypes;
      return types[type];
    },
    checkPayedMultipleQuittance() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment payer les éléments sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.payedWithAgregator
      })
    },
    checkPayed(item) {
      localStorage.setItem("selectedQuittanceID", JSON.stringify(item.id))
      
      this.selectedQuittance = item;
      this.crrID = item.id;
      this.formUpdatedItem = { ...item };
      this.payedWithAgregator()
    },
    DetailsPayment(quittance, subscriberProfil) {
      let profil, userFullname, type, time, comment

      if(quittance.paymentRequest.data && Object.keys(quittance.paymentRequest.data).length !== null) {
        profil = this.whoIs(quittance.paymentRequest.data.userType);
        userFullname = quittance.paymentRequest.data.userFullname;
        type = this.paymentMethod.find(
          (el) => el.id == quittance.paymentRequest.type
        ).label;
        time = quittance.paymentRequest.data.datePayment
        comment = quittance.paymentRequest.data.comment
          ? quittance.paymentRequest.data.comment
          : "";
      }

      let x = `Payé par ${profil ? profil : "Souscripteur"} (${
        userFullname ? userFullname : subscriberProfil
      }) - Date : ${time} - Mode paiement : ${type}, Commentaire : ${comment}`;
      return x;
    },
    async CotisationDownloadFile(quittance, byOpen) {
      this.woIsAction = quittance.id;
      this.loadingDownloadQuittance = true;
      let filesname

      if(quittance.paymentStatus == 'required') {
        let gCotisation = await this.applyGenerateCotisation({
          projectId: this.item,
          quittanceId: quittance.id,
        })
        filesname = [
          {
            name: "Cotisation.pdf", 
            filename: gCotisation.data.currPdfName,
          }
        ]
      } else if (quittance.paymentStatus == 'payOff'){
        let gCotisationSolde = await this.applyGenerateCotisationSolde({
          projectId: this.item,
          quittanceId: quittance.id,
        })
        filesname = [
          {
            name: "Cotisation solde.pdf", 
            filename: gCotisationSolde.data.currPdfName,
          }
        ]
      }

      try {
        await this.applyPutQuittance({ // Update current quittance
          quittanceId: quittance.id,
          ...quittance,
          filesname,
        })
        if(byOpen) {
          window.open(this.showFileData(filesname[0].filename), "_blank")
        }
        this.acceptAlert({
            color: "success",
            text: "Quittance a été téléchargée avec succès",
            title: "Téléchargement",
          })

        this.woIsAction = "";
        this.loadingDownloadQuittance = false;
        return filesname[0].filename
      } catch (err) {
        console.log(err)
        this.acceptAlert({
          color: "danger",
          text: "Une erreur s'est produite",
          title: "Téléchargement",
        })
        this.woIsAction = "";
        this.loadingDownloadQuittance = false;
      }
    },
    checkViewFile(item) {
      window.open(this.showFileData(item.filename), "_blank");
    },

    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    onPeriodicityOfPayment(period) {
      let x = this.allPeriodicityOfPayment.find((el) => el.id === period);
      if (x) return x.label;
    },
    onFormatAmount(amount) {
      if (amount) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return 0;
      }
    },
    onCumulTotal(nbrQ) {
      let total = 0;

      if (nbrQ) {
        for (let index = 0; index < nbrQ; index++) {
          if(this.dataProject.quittances[index].paymentStatus == 'payOff') {
            total += parseInt(this.dataProject.quittances[index].cotisationTTC);
          }
        }
        total = parseInt(total) - this.propositionChoose.accessoir;
      }

      return total;
    },
    payedWithAgregator() {
      this.closeIt = false;

      let newData = {}
      let newAmount = 0

      if(this.crrIDs.length !== 0) { // Paiement multiple
        newAmount = this.cumulAmountPayed
      } else {
        newAmount = this.selectedQuittance.cotisationTTC
      }

      if(this.dataProject.productName == "AHR" || this.dataProject.productName == "AAE") {
        newData = {
          quittanceId: this.crrIDs.length !== 0 ? null : JSON.parse(localStorage.getItem("selectedQuittanceID")), 
          quittanceIDs: this.crrIDs,
          isMultiple: this.crrIDs.length !== 0 ? true : false 
        }
      }

      openKkiapayWidget({
        amount: newAmount, 
        position:"right",
        callback:"",
        data: {
          ...newData, 
          paymentRequest: {
            type: "momoCard",
            data: {
              datePayment: moment(new Date()).format("D MMMM YYYY à HH:mm"),
              userType: this.user.type,
              userId: this.user.id,
              userFullname: this.user.person.firstname + " " + this.user.person.lastname,
            },
          }
        },
        theme:"#009940",
        sandbox:"true", // changer in PROD
        key: global.SANDBOXAPIKEYKKIAPAY, // change in PROD : APIKEYKKIAPAY
      })
    },

    onDisplayQuittance(isClose) {
      this.popupUpdateQuittance = isClose;
    },

    async fetchApplyPutProductContent(paymentRequest) {
      if(this.crrIDs.length !== 0) {
        let promises = []

        let _this = this
        this.crrIDs.forEach(function (qID, index) {
          promises.push((async function (qID) {
            await _this.applyPutQuittance({
              quittanceId: qID,
              transactionId: paymentRequest.data.transactionId,
              paymentRequest,
            })
          })(qID))
        })

        await Promise.all(promises)
      } else {
        console.log("toto", this.selectedQuittance)
        if(this.dataProject.productName == "AHR" || this.dataProject.productName == "AAE") {
          await this.applyPutQuittance({
            quittanceId: JSON.parse(localStorage.getItem("selectedQuittanceID")),
            transactionId: paymentRequest.data.transactionId,
            paymentRequest,
          })
        }
      }
      this.quittanceSelected = []
      this.crrIDs = []
      localStorage.removeItem("selectedQuittanceID")
    },
  },
  watch: {
    quittanceSelected() {
      this.crrIDs = []
      this.cumulAmountPayed = 0

      this.quittanceSelected.forEach(el => {
        if(el.paymentStatus == 'required') {
          this.crrIDs.push(el.id);
          this.cumulAmountPayed += parseInt(el.cotisationTTC)
        }
      })
    },
    item() {
      this.initDataLocal();
    },
    popupUpdateQuittance() {
      this.$emit("onDisplayQuittanceDetail", !this.popupUpdateQuittance);
    },
    closeIt(val) {
      this.$emit("onDisplayQuittanceDetail", val);
    },
    popupAgregateur() {
      this.$emit("onDisplayQuittanceDetail", !this.popupAgregateur);
    },
  },
};
</script>
<style scoped>
.aab-contract-infos-title {
  color: rgba(var(--vs-primary), 1);
  font-size: 22px;
  font-weight: bold;
}
</style>